import React from "react"

const MessagingIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.964 26.5333L5 31V25H3C2.46957 25 1.96086 24.7893 1.58579 24.4142C1.21071 24.0391 1 23.5304 1 23V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H29C29.5304 1 30.0391 1.21071 30.4142 1.58579C30.7893 1.96086 31 2.46957 31 3V11"
        stroke="var(--body-text)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9976 22C20.9976 22.5304 21.2083 23.0391 21.5833 23.4142C21.9584 23.7893 22.4671 24 22.9976 24C23.528 24 24.0367 23.7893 24.4118 23.4142C24.7868 23.0391 24.9976 22.5304 24.9976 22C24.9976 21.4696 24.7868 20.9609 24.4118 20.5858C24.0367 20.2107 23.528 20 22.9976 20C22.4671 20 21.9584 20.2107 21.5833 20.5858C21.2083 20.9609 20.9976 21.4696 20.9976 22V22Z"
        stroke="var(--body-text)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6958 14.2667L25.2865 16.204C25.3835 16.5254 25.599 16.7981 25.8893 16.9666C26.1797 17.1352 26.5233 17.1872 26.8505 17.112L28.8145 16.6573C29.1866 16.5729 29.576 16.61 29.9255 16.7632C30.275 16.9164 30.5662 17.1777 30.7562 17.5085C30.9463 17.8394 31.0252 18.2226 30.9815 18.6016C30.9378 18.9807 30.7736 19.3358 30.5132 19.6147L29.1385 21.096C28.9091 21.3427 28.7816 21.6671 28.7816 22.004C28.7816 22.3409 28.9091 22.6653 29.1385 22.912L30.5132 24.3933C30.7736 24.6722 30.9378 25.0273 30.9815 25.4064C31.0252 25.7854 30.9463 26.1686 30.7562 26.4994C30.5662 26.8303 30.275 27.0916 29.9255 27.2448C29.576 27.398 29.1866 27.4351 28.8145 27.3507L26.8505 26.896C26.5233 26.8208 26.1797 26.8728 25.8893 27.0414C25.599 27.2099 25.3835 27.4825 25.2865 27.804L24.6958 29.7333C24.5863 30.0987 24.3619 30.419 24.0559 30.6468C23.7499 30.8745 23.3786 30.9975 22.9972 30.9975C22.6157 30.9975 22.2445 30.8745 21.9385 30.6468C21.6325 30.419 21.408 30.0987 21.2985 29.7333L20.7092 27.796C20.6119 27.4744 20.3962 27.2018 20.1056 27.0333C19.815 26.8647 19.4713 26.8128 19.1439 26.888L17.1812 27.3427C16.8091 27.4271 16.4197 27.39 16.0702 27.2368C15.7207 27.0836 15.4295 26.8223 15.2395 26.4915C15.0494 26.1606 14.9705 25.7774 15.0142 25.3984C15.0579 25.0193 15.2221 24.6642 15.4825 24.3853L16.8559 22.904C17.0853 22.6573 17.2128 22.3329 17.2128 21.996C17.2128 21.6591 17.0853 21.3347 16.8559 21.088L15.4825 19.6067C15.2221 19.3278 15.0579 18.9727 15.0142 18.5936C14.9705 18.2146 15.0494 17.8314 15.2395 17.5005C15.4295 17.1697 15.7207 16.9084 16.0702 16.7552C16.4197 16.602 16.8091 16.5649 17.1812 16.6493L19.1439 17.104C19.4713 17.1792 19.815 17.1273 20.1056 16.9587C20.3962 16.7902 20.6119 16.5176 20.7092 16.196L21.2985 14.2587C21.4089 13.8935 21.6341 13.5737 21.9406 13.3467C22.2471 13.1197 22.6187 12.9976 23.0002 12.9985C23.3816 12.9994 23.7526 13.1232 24.0581 13.3517C24.3635 13.5802 24.5872 13.901 24.6958 14.2667V14.2667Z"
        stroke="var(--body-text)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MessagingIcon
