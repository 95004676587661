import React from "react"

const LearnMoreIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3335 24.1693L16.0002 28.1693"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 28.1693L30.6667 24.1693"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.3335 24.1693L8.6575 22.2506"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.3335 30.2507V24.336"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.667 24.336V30.2507"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 30.9173V28.1693"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6668 8.08264C20.6668 10.66 19.8895 17.416 16.0002 17.416C12.1108 17.416 11.3335 10.6666 11.3335 8.08264C11.3335 3.41598 16.0002 1.08264 16.0002 1.08264C16.0002 1.08264 20.6668 3.41598 20.6668 8.08264Z"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0003 8.25065C16.1844 8.25065 16.3337 8.10141 16.3337 7.91732C16.3337 7.73322 16.1844 7.58398 16.0003 7.58398C15.8162 7.58398 15.667 7.73322 15.667 7.91732C15.667 8.10141 15.8162 8.25065 16.0003 8.25065Z"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3467 23.8413V22.4973C13.4536 21.861 13.7858 21.2843 14.2826 20.8725C14.7793 20.4608 15.4076 20.2413 16.0527 20.2543C16.6978 20.2672 17.3168 20.5116 17.7967 20.943C18.2765 21.3744 18.5853 21.9639 18.6667 22.604V23.8413"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9107 16.2146L11.8334 16.7546C11.2243 17.078 10.6662 17.4895 10.1774 17.976C10.0604 18.1015 9.90893 18.1896 9.742 18.2293C9.57507 18.269 9.40014 18.2585 9.23917 18.1991C9.07819 18.1396 8.93836 18.034 8.83723 17.8954C8.7361 17.7568 8.67818 17.5914 8.67075 17.42C8.55646 16.4209 8.76927 15.4117 9.27726 14.5438C9.78524 13.6759 10.561 12.9962 11.4881 12.6066"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0894 16.2146L20.1667 16.7546C20.7758 17.078 21.3339 17.4895 21.8227 17.976C21.9397 18.1015 22.0912 18.1896 22.2581 18.2293C22.425 18.269 22.6 18.2585 22.7609 18.1991C22.9219 18.1396 23.0617 18.034 23.1629 17.8954C23.264 17.7568 23.3219 17.5914 23.3294 17.42C23.4436 16.4209 23.2308 15.4117 22.7228 14.5438C22.2149 13.6759 21.4391 12.9962 20.512 12.6066"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6668 24.1693L23.3428 22.2506"
        stroke="var(--body-text-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LearnMoreIcon
